<template>
  <CForm
    id="frm-rapid-insight"
    :data-downloaded="fileDownloaded"
    @submit.prevent="() => false"
  >
    <CJumbotron color="white">
      <CRow>
        <CCol>
          <CImg :src="$brand('modios-rapid-insight.png', '/img')" width="280" />
        </CCol>
      </CRow>
    </CJumbotron>
    <CCard :class="{ 'is-loading': isSaving || isWaiting }">
      <CCardBody>
        <CRow v-taxengine>
          <CCol col="6">
            <MSelect
              :options="taxEngineOptions"
              :value.sync="taxEngineType"
              placeholder="Tax Engine"
              label="Tax Engine"
            />
          </CCol>
        </CRow>
        <CRow v-show="taxEngineType !== 'AVA'">
          <CCol>
            <MFileInput
              name="file"
              label="Configuration File"
              :accept="acceptableTypes"
              :value="file"
              @change="fileChanged"
            />
          </CCol>
        </CRow>
        <CRow v-show="taxEngineType === 'AVA'">
          <CCol col="6">
            <div style="display: flex">
              <div style="width: 85%">
                <MSelect
                  id="execute-test-run_connectivityId"
                  label="Tax Engine Connection"
                  placeholder="Connection Name"
                  required
                  :loading="connectionsLoading"
                  :options="connectionsOptions"
                  :value.sync="connectivityId"
                />
              </div>
              <div style="width: 15%; min-width: 50px; margin-top: 28px">
                <MAddButton
                  style="margin-left: 5px"
                  title="Create New Connection"
                  @click="
                    () => {
                      showEditModal = true;
                    }
                  "
                />
              </div>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="d-flex">
            <CButtonToolbar style="margin-left: auto; margin-right: 0">
              <CButton
                class="mr-1"
                color="secondary"
                @click="$router.push({ name: 'Dashboard' })"
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                class="ml-3"
                :disabled="!canSubmit"
                @click="handleSubmit('EXCEL')"
                ><CIcon name="cil-file-excel" class="mr-2" />As Excel</CButton
              >
              <CButton
                color="primary"
                class="ml-1"
                :disabled="!canSubmit"
                @click="handleSubmit('TEXT')"
                ><CIcon name="cil-file" class="mr-2" />As Text</CButton
              >
            </CButtonToolbar>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
    <EditTaxEngineConnectionModal
      v-if="showEditModal"
      :show.sync="showEditModal"
      only-tax-engine="AVA"
      @refresh="refreshConnections"
    />
  </CForm>
</template>
<script>
import api from '@/api';
import { MFileInput } from '@/components/form';
import EditTaxEngineConnectionModal from '@/components/modal/EditTaxEngineConnectionModal';
import { MAddButton } from '@/components/Buttons';
import { mapGetters } from 'vuex';
import { formsMixin, mapFormProps } from '@/mixins/forms-mixin';
import { VUE_APP_MAX_RI_SIZE } from '@/EnvVars';

function getFilename(disposition) {
  let parts = disposition.split('=');
  return parts[1].replaceAll(/"/gi, '');
}
export default {
  name: 'RapidInsightForm',
  components: {
    MFileInput,
    MAddButton,
    EditTaxEngineConnectionModal,
  },
  mixins: [formsMixin],
  data() {
    let defaultEngine = null;
    if (this.$store.getters['auth/hasSingleTaxEngine']) {
      defaultEngine = this.$store.getters['auth/defaultTaxEngine'];
    }
    return {
      requiredFields: ['file'],
      eventSubscribe: null,
      isWaiting: false,
      showEditModal: false,
      formObject: {
        taxEngineType: defaultEngine,
        file: null,
        connectivityId: null,
      },
      fileDownloaded: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'hasSingleTaxEngine',
      'defaultTaxEngine',
      'taxEngines',
    ]),
    ...mapFormProps(['file', 'taxEngineType', 'outputType', 'connectivityId'], {
      rootObjectName: 'formObject',
    }),
    ...mapGetters('testing', ['connections', 'connectionsLoading']),
    canSubmit() {
      if (!this.selectedTaxEngineType) {
        return false;
      } else if (this.selectedTaxEngineType === 'AVA') {
        return !!this.connectivityId;
      } else {
        return !!this.file;
      }
    },
    acceptableTypes() {
      let types = ['.csv', '.txt', '.xml', '.zip', '.tsv'];
      if (this.selectedTaxEngineType === 'SBX') {
        types = ['.xml', '.zip'];
      } else if (this.selectedTaxEngineType === 'VTX') {
        types = ['.csv', '.txt', '.tsv', '.zip'];
      }
      return types.reduce((prv, cur) => {
        return `${prv},${cur}`;
      });
    },
    connectionsOptions() {
      return this.connections
        .filter((o) => (o.engineType || o.taxEngineType) === 'AVA')
        .map((o) => {
          return Object.freeze({
            value: o.id,
            label: o.name,
          });
        });
    },
    taxEngineOptions() {
      return (this.taxEngines || []).map((value) => {
        let label = value;
        switch (value) {
          case 'AVA':
            label = 'Avalara';
            break;
          case 'SBX':
            label = 'ONESOURCE';
            break;
          case 'VTX':
            label = 'Vertex';
            break;
          case 'SOV':
            label = 'Sovos';
            break;
        }
        return {
          value,
          label,
        };
      });
    },
    selectedTaxEngineType() {
      if (this.hasSingleTaxEngine) {
        return this.defaultTaxEngine;
      }

      return this.taxEngineType;
    },
  },
  beforeMount() {
    this.refreshConnections();
  },
  beforeDestroy() {
    try {
      this.shutdownEventStream();
    } catch (err) {
      // Ignore
    }
  },
  methods: {
    refreshConnections() {
      this.$store.dispatch('testing/fetchConnections');
    },
    fileChanged(files) {
      if (!files || !files[0]) {
        this.file = null;
        this.resetForm();
      } else {
        let file = files[0];
        this.file = file;
        this.clearErrors();
        if (VUE_APP_MAX_RI_SIZE > 0) {
          const rawSize = this.file.size;
          let maxSize = VUE_APP_MAX_RI_SIZE;
          if (!file.name.endsWith('.zip')) {
            maxSize = VUE_APP_MAX_RI_SIZE * 3;
          }

          if (rawSize > maxSize) {
            this.$swal
              .fire({
                icon: 'error',
                title: 'Contact Support',
                text: 'The provided configuration is too large to process without possible errors. Please contact support to have this file processed for you.',
              })
              .then(() => {
                this.resetForm();
              });
          }
        }
      }
    },
    /**
     * Download the rapid insight for this requests ID.
     */
    handleDownload(requestId) {
      const self = this;
      this.$log.debug(`Requesting download for '${requestId}'`);
      return api.rapidInsight
        .download(this.selectedTaxEngineType, requestId)
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            getFilename(response.headers['content-disposition']),
          );
          document.body.appendChild(link);
          link.click();
          self.fileDownloaded = true;
          self.$swal
            .fire({
              icon: 'success',
              title: 'Complete',
              text: 'The Rapid Insight has been generated successfully, check your download folder.',
            })
            .then(() => {
              self.$log.debug('Rapid Insight downloaded.');
              link.remove();
              window.URL.revokeObjectURL(url);
            })
            .then(() => {
              self.resetForm();
            });
        })
        .catch((err) => {
          self.$log.error('Error downloading rapid insight', err);
          self.$swal({
            title: 'Download Error',
            icon: 'error',
            text: 'Errors occurred downloading the results, retry and contact support if the issue persists.',
            showCancelButton: false,
          });
        });
    },
    shutdownEventStream() {
      this.$log.debug('Shutting down event stream...');
      this.$nextTick(() => {
        this.isWaiting = false;
        try {
          this.eventSubscribe?.close();
        } catch (err) {
          this.$log.error('Error shutting down event subscription', err);
        }
        this.eventSubscribe = null;
      });
    },
    /**
     * Subscribe to SSE for the rapid insight request.
     */
    handleEventSubscription(requestId, hasRetried = false) {
      // Used as a semaphore to keep downloads from repeating in case we get multiple complete events prior
      // to cancelling the SSE subscription stream.
      var downloaded = false;
      this.$log.debug(`Subscribing to updates for request '${requestId}'`);
      const self = this;
      this.eventSubscribe = api.rapidInsight.subscribe(
        this.selectedTaxEngineType,
        requestId,
        (event) => {
          if (event.status === 'COMPLETE' && !downloaded) {
            downloaded = true;
            this.handleDownload(requestId).finally(() => {
              self.shutdownEventStream();
            });
          } else if (event.status === 'ERROR') {
            self.$log.error('Error generating rapid insight', event);
            self.$swal.fire({
              icon: 'error',
              title: 'Error',
              text:
                event.message ||
                'Error occurred generating the rapid insight, please contact support if the issue continues.',
            });

            self.shutdownEventStream();
          }
        },
        (error) => {
          if (hasRetried) {
            self.$log.error('Error during SSE connection', error);
            self.shutdownEventStream();
            self.$swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Connection to server was lost, please retry',
            });
          } else {
            setTimeout(() => {
              self.handleEventSubscription(requestId, true);
            }, 5000);
          }
        },
      );
    },
    handleSubmit(outputType) {
      if (this.isSaving || this.isWaiting) {
        return;
      }
      this.fileDownloaded = false;
      this.isWaiting = true;
      const self = this;
      this.submitForm(() =>
        api.rapidInsight.submit(
          this.selectedTaxEngineType,
          this.file,
          outputType,
          this.connectivityId,
        ),
      ).then(({ response, error }) => {
        if (response && !error) {
          this.handleEventSubscription(response.requestId);
        } else {
          self.$log.error('Error submitting rapid insight', error);
          self.shutdownEventStream();
          if (
            (error.response && error.response.status === 403) ||
            (response && response.status === 403)
          ) {
            self
              .$swal({
                title: 'Access Denied',
                icon: 'error',
                text: 'You are not licensed for Rapid Insight usage',
                showCancelButton: false,
              })
              .then(() => {
                self.$router.push({ name: 'Dashboard' });
              });
          } else {
            self
              .$swal({
                title: 'Invalid File',
                icon: 'error',
                text: 'The provided file is invalid or corrupt',
                showCancelButton: false,
              })
              .then(() => {
                self.resetForm();
              });
          }
        }
      });
    },
  },
};
</script>
