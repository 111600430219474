import _ from 'lodash';
import { makeUid } from '@/components/form/form-mixins';

const FORM_MODE_CREATE = 'create';
const FORM_MODE_EDIT = 'edit';

export const FormMode = Object.freeze({
  CREATE: FORM_MODE_CREATE,
  EDIT: FORM_MODE_EDIT,
});

export const successMessage = (type, editMode, vm) => {
  let renderedType = type;
  if (type.startsWith('entity.')) {
    renderedType = vm.$tc(type);
  }

  if (editMode) {
    return vm.$t('modal.edit.success', { type: renderedType });
  } else {
    return vm.$t('modal.create.success', { type: renderedType });
  }
};

function getAliasOrDefault(propertyName, aliases) {
  if (_.has(aliases, propertyName)) {
    return _.get(aliases, propertyName, propertyName);
  } else {
    return propertyName;
  }
}

function getComparer(propertyName, comparers) {
  if (_.has(comparers, propertyName)) {
    return _.get(comparers, propertyName);
  } else {
    return null;
  }
}

/**
 * Mapper function for form property binding, similar to Vuex mapGetters.
 *
 * Optional configuration properties:
 * - rootObjectName: The Vue component property name to bind all form data to, if not present it is assumed the form is 'flat'
 *                   and directly assigned as properties of the component.
 * - comparers: A map of <String, Function> where the key is the name of the property (provided in `props`) and the function is called to
 *              determine if a new value is equal to a previous value. All the functions must return Boolean values.
 *              If no comparer is present for a field then basic `===` equality checks are performed.
 * - aliases: A map of <String, String> where the key is the property name provided in `props` and the value is the name of the field
 *            that will be sent in requests or received in responses.
 *
 * @param {Array<String>} props List of property names to generate fields for. These names must be the names used for v-model binding, etc.
 * @param {Object} configuration Optional mapper configuration data.
 * @returns {Object} Configured Vue component instance with computed fields for every entry provided in `props`.
 */
export const mapFormProps = function (
  props = [],
  { rootObjectName, comparers, aliases } = {},
) {
  const hasAliases = !_.isEmpty(aliases);
  const hasComparers = !_.isEmpty(comparers);
  const hasSourceObject = !_.isNil(rootObjectName);

  // Create computed fields for all entries in props[] and bind them to the Vue component (obj).
  // Additionally, add computed error fields for all entries in props[].
  return props.reduce((obj, propertyName) => {
    const alias = hasAliases
      ? getAliasOrDefault(propertyName, aliases)
      : propertyName;

    // Create computed get/set field called whatever the incoming property name is,
    // ex. myProp
    const computed = {
      get() {
        if (hasSourceObject) {
          return this.getFieldValue(
            propertyName,
            _.get(this[rootObjectName], alias),
          );
        } else {
          return this.getFieldValue(propertyName, null);
        }
      },
      set(value) {
        const comparer = hasComparers
          ? getComparer(propertyName, comparers)
          : null;
        if (hasSourceObject) {
          this.setPendingChange(
            propertyName,
            value,
            _.get(this[rootObjectName], alias, comparer),
          );
        } else {
          this.setPendingChange(propertyName, value, null, comparer);
        }
      },
    };

    obj[propertyName] = computed;

    // Create computed getter for error field, ex. myProp_Error for easier binding/reporting.
    const errorField = {
      get() {
        const errorField = _.get(this.errorFields, propertyName);
        if (_.isNil(errorField)) {
          return {
            state: null,
            invalidFeedback: null,
          };
        }

        return {
          state: this.errorState(errorField),
          invalidFeedback: this.errorFeedback(errorField),
        };
      },
    };

    obj[`${propertyName}_Error`] = errorField;
    return obj;
  }, {});
};

export const mapErrorFields = function (fieldNames = []) {
  return fieldNames.reduce((obj, fieldName) => {
    // Create computed getter for error field, ex. myProp_Error for easier binding/reporting.
    const errorField = {
      get() {
        const errorField = _.get(this.errorFields, fieldName);
        if (_.isNil(errorField)) {
          return {
            state: null,
            invalidFeedback: null,
          };
        }

        return {
          state: this.errorState(errorField),
          invalidFeedback: this.errorFeedback(errorField),
        };
      },
    };

    obj[`${fieldName}_Error`] = errorField;
    return obj;
  }, {});
};

export const formsMixin = {
  props: {
    initialFormMode: {
      type: String,
      required: false,
      default() {
        return FORM_MODE_CREATE;
      },
      validator(prop) {
        return prop === FORM_MODE_CREATE || prop === FORM_MODE_EDIT;
      },
    },
  },
  data() {
    return {
      quickCreate: false,
      showToast: false,
      anyChangesSaved: false,
      savedItems: [],
      isSaving: false,
      errors: {
        messages: [],
        fields: {},
      },
      pendingChanges: {},
      requiredFields: [],
      formMode:
        this.initialFormMode === FORM_MODE_EDIT
          ? FormMode.EDIT
          : FormMode.CREATE,
    };
  },
  computed: {
    formId() {
      if (this.$attrs.id) {
        return this.$attrs.id;
      }

      return makeUid();
    },
    defaults() {
      return {};
    },
    editMode() {
      return this.formMode === FormMode.EDIT;
    },
    createMode() {
      return this.formMode === FormMode.CREATE;
    },
    extendedSubmitEvaluation() {
      return true;
    },
    errorResponseAliases() {
      return {};
    },
    /**
     *
     * @returns `true` If the form has met the conditions for being submitted.
     */
    canSubmit() {
      if (this.isSaving) {
        return false;
      }

      if (this.hasRequiredFields) {
        if (this.editMode) {
          return this.hasPendingChanges && this.extendedSubmitEvaluation;
        } else {
          return this.extendedSubmitEvaluation;
        }
      }

      return false;
    },
    hasPendingChanges() {
      return !_.isEmpty(this.pendingFields);
    },
    /**
     *
     * @returns An array of all of the names of the fields with pending changes.
     */
    pendingFields() {
      return _.keys(this.pendingChanges) || [];
    },

    /**
     *
     * @returns An array of all of the names of the fields with errors.
     */
    errorFields() {
      return _.get(this.errors, 'fields') || {};
    },
    /**
     *
     * @returns An array of all of the error messages.
     */
    errorMessages() {
      return _.get(this.errors, 'messages') || [];
    },
    /**
     *
     * @returns `true` If any fields have active errors.
     */
    hasErrors() {
      return _.keys(this.errorFields).length > 0;
    },
    /**
     *
     * @returns `true` If `errorMessages` is not empty.
     */
    hasErrorMessages() {
      return !_.isEmpty(this.errorMessages);
    },
    /**
     * @returns `true` If all of the required fields are not empty.
     */
    hasRequiredFields() {
      if (_.isEmpty(this.requiredFields)) {
        return true;
      }

      for (let field of this.requiredFields) {
        let fieldValue = this[field];
        if (this.isEmptyValue(fieldValue)) {
          return false;
        }
      }

      return true;
    },
  }, // END Computed
  watch: {
    initialFormMode(v) {
      this.formMode = v === FORM_MODE_EDIT ? FormMode.EDIT : FormMode.CREATE;
    },
  },
  methods: {
    propValBind(fieldName) {
      return {
        invalidFeedback: this.invalidFeedback(fieldName),
        isValid: this.isValid(fieldName),
      };
    },
    propId(name) {
      return `${this.formId}-${name}`;
    },
    /**
     * Wrapper a customized submit function in the default call chain. `submitFunction`
     * should handle dispatching a request and always return a promise. This method will
     * then handle the result and display errors, alerts, etc. The resulting promise can then
     * be chained further for customized navigation or handling.
     *
     * @param {Function<Promise>} submitFunction The custom function to call, must return a promise.
     * @returns {Promise<Object>} The returned promise will return the results of `submitFunction` _after_
     * clearing errors and any other default actions have occurred. If any error was caught from `submitFunction`
     * it will be resolved (not rejected) as the second argument so that exception handling can be further customized.
     * Example:
     * submitForm(() => ... do submitting ...).then(({response, error, skipped}) => {
     *  if (response && !error) {
     *    // Do success work...
     *  } else {

     *    // Do error work...
     *  }
     * })
     */
    submitForm(submitFunction, ignoreBadRequest = false) {
      if (this.isSaving) {
        return Promise.resolve({
          response: null,
          error: true,
          skipped: true,
        });
      }

      const self = this;
      this.isSaving = true;
      return new Promise((resolve) => {
        submitFunction()
          .then((res) => {
            self.clearErrors();
            self.$log.debug('Successfully submitted request', res);
            resolve({ response: res, error: null, skipped: false });
          })
          .catch((err) => {
            if (!ignoreBadRequest) {
              self.handleApiError(err);
            }
            // Resolve the error so that the chain can be re-used without raising
            // an uncaught exception.
            resolve({ response: null, error: err, skipped: false });
          })
          .finally(() => {
            self.isSaving = false;
          });
      });
    },
    /**
     *
     * @param {String} fieldName The name of the field to get an error message for.
     * @returns {String?} The error message for the field, if any.
     */
    invalidFeedback(fieldName) {
      return _.get(this[`${fieldName}_Error`], 'invalidFeedback');
    },
    /**
     * @param {*} fieldName  The name of the field to get status for.
     * @returns {Boolean?} `false` If the field has an error, null otherwise.
     */
    isValid(fieldName) {
      return _.get(this[`${fieldName}_Error`], 'state');
    },
    /**
     *
     * @param {String} fieldName The name of the field to get status for.
     * @returns `true` If `fieldName` exists in `this.errorFields`.
     */
    hasError(fieldName) {
      // return _.has(this.errorFields, fieldName);
      return _.get(this[`${fieldName}_Error`], 'state') === true;
    },
    /**
     *
     * @param {String} fieldName The name of the field to check.
     * @returns `true` If the field is a required field.
     */
    isRequiredField(fieldName) {
      return _.includes(this.requiredFields, fieldName);
    },
    /**
     *
     * @param {*} value The value to check.
     * @returns `true` If the value is nullish, an empty collection, or empty String.
     */
    isEmptyValue(value) {
      if (_.isNil(value)) {
        return value !== false && value !== 0;
      } else if (Array.isArray(value)) {
        return _.isEmpty(value);
      } else if (`${value}`.trim().length === 0) {
        return true;
      }

      return false;
    },
    /**
     * Clear all pending changes on the form, e.g. reset.
     */
    clearPendingChanges() {
      this.pendingChanges = {};
    },
    resetForm() {
      this.clearPendingChanges();
      this.clearErrors();
    },
    /**
     * Clear the pending changes for the field.
     * @param {String} fieldName The field to clear changes for.
     */
    clearPendingChange(fieldName) {
      this.pendingChanges = _.omit(this.pendingChanges, fieldName);
    },
    /**
     * Clear all errors on the form.
     */
    clearErrors() {
      this.errors = {
        messages: [],
        fields: {},
      };
    },
    /**
     * Clear all errors for the field.
     * @param {String} fieldName The name of the field to clear errors for.
     */
    clearError(fieldName) {
      if (_.has(this.errorFields, fieldName)) {
        this.errors.fields = _.omit(this.errors.fields, fieldName);
      }
    },
    /**
     *
     * @param {String} fieldName The field to set an error on.
     * @param {String} message The error message to set.
     */
    setFieldError(fieldName, message) {
      if (!_.has(this.errorFields, fieldName)) {
        this.$set(this.errors.fields, fieldName, [message]);
      } else {
        this.errors.fields[fieldName] = [message];
      }
    },
    /**
     * Set a pending change on a field (if necessary). If a pending change is set any
     * existing errors will be cleared. A pending change will only be set if it is different
     * from `originalValue`.
     *
     * @param {String} fieldName The name of the field to set the change to.
     * @param {Object?} value The value to set.
     * @param {Object?} originalValue The previous/original value to compare against.
     * @param {Function<Boolean>?} comparingFunction The comparing function to use in lieu of `===` comparison.
     * @returns {Boolean} `true` if a pending change was set.
     */
    setPendingChange(fieldName, value, originalValue, comparingFunction) {
      // In create mode, we don't need to compare original values, take the short path...
      if (FORM_MODE_CREATE === this.formMode) {
        // console.log('Incoming Value', value);
        if (this.isEmptyValue(value) && _.has(this.pendingChanges, fieldName)) {
          // Removing pending change if empty (back to where we started).
          // console.log(`Removing pending change for '${fieldName}'`);
          this.pendingChanges = _.omit(this.pendingChanges, fieldName);
        } else if (!_.has(this.pendingChanges, fieldName)) {
          // console.log(`$set pending change for '${fieldName}'`);
          // Use $set to ensure change visibility.
          this.$set(this.pendingChanges, fieldName, value);
        } else {
          // console.log(`Updating pending change for '${fieldName}'`);
          this.pendingChanges[fieldName] = value;
          this.clearError(fieldName);
        }
      } else {
        let pushChange = true;
        let equalToOriginal = false;

        // If the original was empty, we needn't compare.
        if (!this.isEmptyValue(originalValue)) {
          if (comparingFunction) {
            // Use the provided comparator.
            pushChange = !comparingFunction(value, originalValue);
          } else {
            // Use basic equality checking.
            pushChange = value !== originalValue;
          }

          // If we have to push a change, not equal to original.
          equalToOriginal = !pushChange;
        }

        if (pushChange) {
          if (!_.has(this.pendingChanges, fieldName)) {
            // Use $set to ensure change visibility.
            this.$set(this.pendingChanges, fieldName, value);
          } else {
            this.pendingChanges[fieldName] = value;
          }

          // If the field is required and the new value is empty, go ahead and set a field
          // error pre-emptively.
          if (this.isRequiredField(fieldName) && this.isEmptyValue(value)) {
            this.setFieldError(
              fieldName,
              'This field is required and cannot be empty',
            );
          } else {
            // Clear any previous entries since the value has changed.
            this.clearError(fieldName);
          }

          // Pending changes were applied.
          return true;
        } else if (equalToOriginal) {
          // If we're back to the original (like a form reset) clear pending changes and errors.
          this.clearPendingChange(fieldName);
          this.clearError(fieldName);
        }
      }

      // No changes were applied.
      return false;
    },
    /**
     * Return the value to use for the provided field name.
     * If a field has a pending change, that value is returned, otherwise
     * the original value or default value is returned.
     *
     * @param {*} fieldName The name of the field to get a value for.
     * @param {*} defaultValue The default value to return if none is present.
     * @returns {*} The pending value, the original value, or `defaultValue` in that order of precendence.
     */
    getFieldValue(fieldName, defaultValue) {
      if (_.has(this.pendingChanges, fieldName)) {
        return _.get(this.pendingChanges, fieldName);
      }

      if (FORM_MODE_CREATE === this.formMode) {
        if (_.has(this.defaults, fieldName)) {
          return _.get(this.defaults, fieldName);
        }
      }

      return defaultValue;
    },
    // TODO: Maybe can be removed/is redundant?
    errorState(field) {
      if (_.isNil(field) || _.size(field) === 0) {
        return null;
      }

      return false;
    },
    // TODO: Maybe can be removed/is redundant?
    errorFeedback(field) {
      if (_.isNil(field) || _.size(field) === 0) {
        return null;
      }

      if (Array.isArray(field)) {
        return field[0];
      }

      return _.toString(field);
    },
    /**
     * Handle bad requests from Axios response.
     * @param {*} response Axios API response object.
     * @returns {Promise}
     */
    handleBadRequest(response) {
      try {
        const data = JSON.parse(JSON.stringify(response.data || {}));
        this.$log.debug('Bad Request response data', data);
        // Expected fields are message, errors.
        const dataErrors = data.errors || [];
        const dataMessage = data.message;
        let text = 'Invalid Request';
        if (_.isEmpty(dataErrors)) {
          // This handles import error responses
          if (data.recordErrors) {
            let fieldErrors = {};
            // 'form' errors should come back with a fieldName and no (or 0) line number.
            data.recordErrors
              .filter(
                (e) =>
                  !_.isNil(e.fieldName) &&
                  (_.isNil(e.lineNumber) || e.lineNumber === 0),
              )
              .forEach((e) => {
                let fieldName = _.get(
                  this.errorResponseAliases,
                  e.fieldName,
                  e.fieldName,
                );
                fieldErrors[fieldName] = e.message;
              });
            this.errors = {
              messages: data.messages || [],
              fields: fieldErrors,
            };
            text = 'Please fix the marked errors';
          } else {
            this.errors = {
              // TODO: Formulate better message and put bugsnag notify if dataMessage is empty.
              // TODO: i18n
              messages: [dataMessage || 'Invalid request'],
              fields: {},
            };
            if (dataMessage) {
              text = dataMessage;
            }
          }
        } else {
          // Map the incoming field errors, taking the default message from the field error.
          let fieldErrors = {};
          dataErrors
            .filter((e) => !_.isNil(e.field))
            .forEach((e) => {
              let fieldName = _.get(
                this.errorResponseAliases,
                e.field,
                e.field,
              );
              fieldErrors[fieldName] = [e.defaultMessage];
            });
          if (_.isEmpty(fieldErrors)) {
            this.errors = {
              // Only use dataMessage if we didn't get any field errors back.
              // TODO: Formulate better message and put bugsnag notify if dataMessage is empty.
              // TODO: i18n
              messages: _.isEmpty(fieldErrors)
                ? [dataMessage || 'Unknown errors occurred']
                : [],
              fields: {},
            };
          } else {
            this.errors = {
              messages: [],
              fields: fieldErrors,
            };
            text = 'Please fix the marked errors.';
          }
        }
        return this.$swal({
          title: 'Invalid Request',
          icon: 'error',
          text: text,
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      } catch (ex) {
        this.$log.error('Error handling bad request response', ex);

        this.errors = {
          // TODO: i18n
          messages: ['Errors occurred handling the response from the server.'],
          fields: {},
        };
        // TODO: BugSnag
        return this.$swal({
          title: 'Invalid Request',
          icon: 'error',
          text: 'The request could not be accepted, please try again or contact an administrator.',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      }
    },
    /**
     * Handle an Axios API error response.
     * @param {*} error Axios error object.
     * @returns {Promise}
     */
    handleApiError(error) {
      if (!error.response) {
        this.$log.warn('Error submitting data, no response returned', error);
        this.errors = {
          messages: [
            'Errors occurred communicating with the server, no changes were saved.',
          ],
          fields: {},
        };
      } else {
        const response = error.response;
        const status = response.status || 500;
        let title = 'Error';
        switch (status) {
          case 400:
            return this.handleBadRequest(response);
          case 401:
            title = 'Unauthorized';
            this.errors = {
              // TODO: i18n
              messages: ['You are not authorized to perform this action'],
              fields: {},
            };
            break;
          case 403:
            title = 'Forbidden';
            this.errors = {
              // TODO: i18n
              messages: [
                'You do not have sufficient permission to perform this action',
              ],
              fields: {},
            };
            break;
          default:
            this.errors = {
              // TODO: i18n
              messages: [
                'Errors occurred submitting data, no changes were saved',
              ],
              fields: {},
            };
            break;
        }
        return this.$swal({
          title: title,
          icon: 'error',
          text: _.get(this.errors, 'messages', [])[0] || error,
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      }
    },
    showSuccess(message) {
      return this.$swal({
        title: 'Success',
        icon: 'success',
        text: message,
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    },
    successMessage(type) {
      return successMessage(type, this.editMode, this);
    },
  }, // END Methods
};
